"use client";

import { usePathname } from 'next/navigation'
import Link from "next/link";
import { useStore } from '@/stores';
import usePersistStore from '@/hooks/usePersistStore';
import { cn } from '@/libs/cn';
import {
  Modal,
  ModalContent,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure
} from '@nextui-org/react';
import { getCurrencySymbol } from '@/utils/formatCurrency';


const suggestedCurrencyList = [
  { "title": "Euro", "value": "EUR" },
  { "title": "U.S. Dollar", "value": "USD" },
  { "title": "Australian Dollar", "value": "AUD" },
  { "title": "New Zealand Dollar", "value": "NZD" },
  { "title": "Thai Baht", "value": "THB" },
  { "title": "Canadian Dollar", "value": "CAD" },
  { "title": "Singapore Dollar", "value": "SGD" },
  { "title": "Chinese Yuan", "value": "CNY" },
  { "title": "Hong Kong Dollar", "value": "HKD" },
  { "title": "Philippine Peso", "value": "PHP" }
]

const currencyList = [
  { "title": "Malaysian Ringgit", "value": "MYR" },
  { "title": "New Taiwan Dollar", "value": "TWD" },
  { "title": "Korean Won", "value": "KRW" },
  { "title": "Israeli Shekel", "value": "ILS" },
  { "title": "U.A.E Dirham", "value": "AED" },
  { "title": "Swiss Franc", "value": "CHF" },
  { "title": "Danish Krone", "value": "DKK" },
  { "title": "Egyptian Pound", "value": "EGP" },
  { "title": "Fijian Dollar", "value": "FJD" },
  { "title": "British Pound", "value": "GBP" },
  { "title": "Indonesian Rupiah", "value": "IDR" },
  { "title": "Indian Rupee", "value": "INR" },
  { "title": "Icelandic Krona", "value": "ISK" },
  { "title": "Jordanian Dinar", "value": "JOD" },
  { "title": "Japanese Yen", "value": "JPY" },
  { "title": "Cambodian Riel", "value": "KHR" },
  { "title": "Lao Kip", "value": "LAK" },
  { "title": "Moroccan Dirham", "value": "MAD" },
  { "title": "Malagasy Ariary", "value": "MGA" },
  { "title": "Macau Pataca", "value": "MOP" },
  { "title": "Mauritian Rupee", "value": "MUR" },
  { "title": "Mexican Peso", "value": "MXN" },
  { "title": "Norwegian Krone", "value": "NOK" },
  { "title": "Omani Rial", "value": "OMR" },
  { "title": "Qatar Riyal", "value": "QAR" },
  { "title": "Swedish Krona", "value": "SEK" },
  { "title": "Turkish Lira", "value": "TRY" },
  { "title": "Vietnamese Dong", "value": "VND" },
  { "title": "South African Rand", "value": "ZAR" }
]

function RightNav() {
  const pathname = usePathname()
  const store = usePersistStore(useStore, (state) => state)
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const handleCurrencySelected = (currency: string) => {
    window.location.reload();
    store?.setCurrency(currency);
  }


  return (
    <>
      {
        pathname === "/booking" ? (
          <li className="group/nav relative flex h-full items-center">
            <div
              className="font-degular-display-semibold flex cursor-pointer flex-row items-center space-x-[12px] rounded-full px-[8px] text-base !text-white hover:bg-white/30">
              <p className='font-degular-display-semibold text-nowrap ' onClick={onOpen}>
                {getCurrencySymbol(store?.currency || 'USD')} {store?.currency}
              </p>
            </div>
            <Modal isOpen={isOpen} onOpenChange={onOpenChange} size='5xl' placement='auto'>
              <ModalContent>
                {() => (
                  <>
                    <ModalBody className='flex-col px-[32px] py-[24px] h-[80vh]'>
                      <p className='text-black text-[20px] px-[1px] font-degular-display-semibold'>Select your currency</p>
                      <span className='text-[#888] text-[12px] px-[1px]'>Where applicable prices will be converted to, and shown in, the currency that you select. The currency you pay in may differ based on your reservation, and a service fee may also apply.</span>
                      <div className='h-[calc(80vh-120px)] px-[1px] overflow-y-auto
                          [&::-webkit-scrollbar]:w-2
                          [&::-webkit-scrollbar-track]:rounded-full
                          [&::-webkit-scrollbar-track]:bg-gray-100
                          [&::-webkit-scrollbar-thumb]:rounded-full
                          [&::-webkit-scrollbar-thumb]:bg-gray-300
                          dark:[&::-webkit-scrollbar-track]:bg-neutral-700
                          dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500'>
                        <p className='text-black text-[20px] my-[16px] font-degular-display-semibold '>Suggested for you</p>
                        <div className='grid grid-cols-2 lg:grid-cols-4 gap-x-[30px] lg:gap-x-[60px] gap-y-[12px] lg:gap-y-[20px] px-2'>
                          {
                            suggestedCurrencyList.map((currency, index) => (
                              <button
                                key={index}
                                className={
                                  cn(
                                    'flex flex-col items-start !cursor-pointer hover:bg-[#E7E7E7] p-2 rounded-md',
                                    currency.value == store?.currency && "ring-2 ring-black"
                                  )
                                }
                                onClick={() => handleCurrencySelected(currency.value)}
                              >
                                <p className='text-[14px] text-black text-left '>{currency.title}</p>
                                <p className='text-[12px] text-[#888]'>{currency.value}</p>
                              </button>
                            ))
                          }
                        </div>
                        <p className='text-black text-[20px] my-[16px] font-degular-display-semibold '>More currencies</p>
                        <div className='grid grid-cols-2 lg:grid-cols-4 gap-x-[30px] lg:gap-x-[60px] gap-y-[12px] lg:gap-y-[20px] px-2'>
                          {
                            currencyList.map((currency, index) => (
                              <button
                                key={currency.value + index}
                                className={
                                  cn(
                                    'flex flex-col items-start !cursor-pointer hover:bg-[#E7E7E7] p-2 rounded-md',
                                    currency.value == store?.currency && "ring-2 ring-black"
                                  )
                                }
                                onClick={() => handleCurrencySelected(currency.value)}
                              >
                                <p className='text-[14px] text-black text-left '>{currency.title}</p>
                                <p className='text-[12px] text-[#888]'>{currency.value}</p>
                              </button>
                            ))
                          }
                        </div>
                      </div>

                    </ModalBody>
                  </>
                )}
              </ModalContent>
            </Modal >
          </li >
        ) : (
          <li className="group/nav relative flex h-full items-center">
            <Link
              href="https://supplier.travelthru.com/"
              target="_blank"
              className="!no-underline"
            >
              <div
                className="font-degular-display-semibold flex cursor-pointer flex-row items-center space-x-[12px]
              px-[14px] h-[40px] rounded-[10px] bg-white text-black hover:bg-[#EDEDED] text-[12px] lg:text-[14px]"
              >
                <p className=" text-center leading-none ">Become our Supplier</p>
              </div>
            </Link>
          </li>
        )
      }
    </>
  )
}

export default RightNav