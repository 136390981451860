const formatCurrency = (number: number, currency: string) => {
  currency = currency || "USD"
  return number?.toLocaleString("en-US", {
    style: "currency",
    currency,
  });
};

export default formatCurrency;

export function getCurrencySymbol(currencyCode: string): string {
  if (!currencyCode) return '';
  
  try {
    return new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: currencyCode
    })
      .format(0)
      .replace(/[\d.,\s]/g, '');
  } catch (error) {
    console.error('Error getting currency symbol:', error);
    return currencyCode;
  }
}